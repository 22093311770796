<template>
	<div
		class="modal fade"
		id="refundCustomerModal"
		tabindex="-1"
		role="dialog"
		aria-labelledby="refundCustomerModalLabel"
		aria-hidden="true"
		>
		<div
			class="modal-dialog modal-dialog-centered"
			role="document"
			v-if="removeRefund"
			>
			<div class="modal-content">
				<div class="modal-header" id="refund-header">
					<div>
						<h3 class="modal-title" id="refundCustomerLabel">Refund amount</h3>
						<p class="sub-modal-title">
							Kindly select a percentage to be refunded to the customer
						</p>
					</div>
				</div>
				<div class="modal-body" id="refund-body">
					<form @submit.prevent="sendRefund()">
						<div class="row">
							<div class="col-12 d-flex flex-column py-2">
								<label class="form-label">Trip amount</label>
								<input
									type="text"
									class="input-refund"
									readonly
									:placeholder="booking?.userRoute?.cost"
									/>
							</div>
							<div class="col-12 d-flex flex-column py-2">
								<label class="form-label">Percentage</label>
								<select v-model="form.percent" class="select-refund" required>
									<option value="null"></option>
									<option
										v-for="percent in percentages"
										:key="percent"
										:value="percent.replace('%', '')"
										>
										{{ percent }}
									</option>
								</select>
								<p class="text-danger py-1" v-if="$v.form.percent.$error">
									This field is required
								</p>
							</div>
							<div class="col-12 d-flex flex-column py-2">
								<label class="form-label">Description</label>
								<textarea
									class="textarea-refund"
									name="refund-description"
									id=""
									cols="20"
									rows="4"
									v-model="form.description"
									></textarea>
								<p class="text-danger py-1" v-if="$v.form.description.$error">
									This field is required
								</p>
							</div>
							<div class="col-12 d-flex justify-content-end py-2 mt-3">
								<button
									class="btn btn-dark refund-btn-cancel col-3 mr-3"
									@click="resetModal($event)"
									aria-label="close"
									data-dismiss="modal"
									:disabled="processing"
									>
									Cancel
								</button>
								<button
									:disabled="processing || $v.form.$invalid"
									type="submit"
									class="btn btn-dark refund-btn-proceed col-3"
									:class="[processing ? 'btn-secondary' : 'btn-dark']"
									>
									{{ processing ? 'Loading...' : `Proceed` }}
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div
			class="modal-dialog modal-dialog-centered"
			role="document"
			v-if="showSucess"
			>
			<div class="modal-content" id="refund-success-modal">
				<div
					class="d-flex flex-column align-items-center justify-content-center p-3"
					>
					<img src="@/assets/img/icons/refund-success.svg" alt="" />
					<h3 class="pt-3">Refund was successful</h3>
					<p class="refund-success-p">
						The customer has been successfully refunded and will be notified
					</p>
					<button
						class="btn btn-lg text-black refund-btn-success"
						@click="resetModal($event)"
						aria-label="close"
						data-dismiss="modal"
						>
						Dismiss
					</button>
				</div>
			</div>
		</div>
		<button
			type="button"
			class="hidden"
			data-toggle="modal"
			data-target="#refundCustomerModal"
			ref="modalToggle"
			></button>
	</div>
</template>

<script>
import { percentages } from '@/utils/percentage.js'
import ModalMixin from '@/mixins/modal'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    show: Boolean,
    booking: Object,
    scheduleId: Number
  },
  mixins: [ModalMixin, validationMixin],
  data () {
    return {
      percentages,
      removeRefund: true,
      showSucess: false,
      processing: false,
      form: {
        percent: null,
        description: ''
      }
    }
  },
  validations: {
    form: {
      percent: {
        required
      },
      description: {
        required
      }
    }
  },
  methods: {
    async sendRefund () {
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        this.processing = true
        const amount = parseInt(this.form?.percent)
        try {
          /// v1/user-route-schedules/:scheduleId
          await this.axios.post(
            `v1/user-route-schedules/${this.scheduleId}/refund`,
            {
              amount: amount,
              description: this.form?.description
            }
          )
          this.processing = false
          this.removeRefund = false
          this.showSucess = true
        } catch (err) {
          if (err.response.status == 500) {
            this.$toastr.e(err.response.data.message, 'Error')
            this.processing = false
          } else {
            this.$toastr.e('Please check with Admin', 'Error')
            this.processing = false
          }
        }
      }
    },
    resetModal (e) {
      this.close(e)
      this.processing = false
      this.removeRefund = true
      this.showSucess = false
      this.form.percent = null
      this.form.description = null
    }
  },
  computed: {
    isFormComplete () {
      return !!(this.form.percent !== null || this.form.description !== '')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/partials/_sh-colors.scss';

.refund-modal {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  overflow-y: scroll;
}

#refund-header {
  border: none;
  padding-bottom: 0;
}

#refund-body {
  padding-top: 0;
}

.sub-modal-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $sh-neutral-400;
}

.input-refund {
  height: 50px;
  background-color: $sh-gray-10;
  border: 1px solid $sh-gray-9;
  border-radius: 8px;
  outline: none;
  padding: 16px 12px;
}

.select-refund {
  height: 50px;
  outline: none;
  border-radius: 8px;
  border: 1px solid $sh-gray-9;
  padding: 16px 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.textarea-refund {
  border: 1px solid $sh-gray-9;
  padding: 16px 12px;
  border-radius: 8px;
  resize: none;
  white-space: nowrap;
  overflow-x: scroll;
  outline: none;
}

.refund-btn-cancel {
  background-color: $sh-gray-11;
  border: none;
}

.refund-btn-proceed {
  background-color: $sh-black-1;
}

#refund-success-modal {
  width: 400px;
  text-align: center;
}

.refund-btn-success {
  background-color: $sh-green-500;
  width: 100%;
}

.refund-success-p {
  color: $sh-neutral-600;
}
.invalid-feedback {
  display: block;
}

button:disabled {
  background: #acafae;
  border: none;
}
</style>
